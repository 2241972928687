import * as React from "react";
import { useGlobalParam } from "@cuda-react/core";

interface MfeMetadata {
  url: string;
  name: string;
  htmlTag: string;
}

type MfeManifest = {
  microfrontends: Record<string, MfeMetadata>;
};

type LoadMfeProps = {
  name: string;
};

const getMfeMetadata = async (mfeName: string): Promise<MfeMetadata> => {
  const mfeManifestUrl =
    (await import.meta.env.VITE_MFE_MANIFEST) ?? "/manifest.json";
  const manifest: MfeManifest = await (await fetch(mfeManifestUrl)).json();
  return manifest.microfrontends[mfeName];
};

const loadMfe = async (mfeName: string): Promise<MfeMetadata> => {
  const selectedMfeMetadata = await getMfeMetadata(mfeName);
  await import(selectedMfeMetadata.url);

  return selectedMfeMetadata;
};

const LoadMfe = (props: LoadMfeProps) => {
  const globalParam = useGlobalParam()[0];
  const [mfeMetadata, setMfeMetadata] = React.useState<MfeMetadata | null>(
    null
  );

  React.useEffect(() => {
    const loadAsset = async () => {
      const metadata = await loadMfe(props.name);
      setMfeMetadata(metadata);
    };

    loadAsset();
  }, []);

  if (!mfeMetadata) {
    // TODO: loading state
    return null;
  }

  // TODO: add error boundary
  return React.createElement(mfeMetadata?.htmlTag, {
    "data-global-params": JSON.stringify(globalParam),
  });
};

export default LoadMfe;
